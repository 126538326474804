<template>
	<el-card class="box-card">
		<el-button type="primary" class="d-block mr-0 ml-auto" icon="el-icon-check" @click="save">{{$t('general.save')}}
		</el-button>
		<div class="separator"></div>
		<el-form label-position="top" label-width="100px">
			<el-card shadow="never">
				<div slot="header"><strong>{{$t('account.profile.api-event')}}</strong></div>
				<Box :type="'info'" :active="true" style="margin-top:0">
					<div slot="box-body">
						<ul class="rawUl">
							<li v-html="$t('account.profile.api-event-info-1')"></li>
							<li v-html="$t('account.profile.api-event-info-2')"></li>
						</ul>
					</div>
				</Box>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12">
						<el-form-item :label="$t('account.profile.api-event-link')">
							<el-input v-model="apiEmitter"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
		</el-form>
	</el-card>
</template>

<script>
	const Box = () => import('vue-info-box-element');
	import {
		call,
		get,
		sync
	}
	from 'vuex-pathify';
	import {
		validateUrl
	} from '@/utils/index';
	export default {
		components: {
			Box
		},
		data: () => ({

		}),
		computed: {
			...sync('account/informations@technical', {
				apiEmitter: 'apiEmitter'
			})
		},
		methods: {
			getTechincalData: call('account/GetAffiliateTechnicalData'),
			saveTechnicalData: call('account/SaveAffiliateTechnicalData'),
			async save() {
				if (this.apiEmitter && !validateUrl(this.apiEmitter)) {
					this.sbMsg({
						type: 'error',
						message: this.$t('account.profile.api-event-invalid')
					});
					return false;
				}
				try {
					await this.saveTechnicalData();
					this.sbMsg({
						type: 'success',
						message: this.$t('general.changes-saved')
					});
				} catch (err) {
					console.log(err)
					this.$reportToSentry(err, {
						extra: {
							fn: 'saveTechnicalData',
							params: this.$store.get('account/informations@technical')
						}
					});
				}
			}
		},
		async mounted() {
			try {
				await this.getTechincalData();
			} catch (err) {
				this.$reportToSentry(err, {
					extra: {
						fn: 'getTechnicalData'
					}
				});
			}
		}
	};
</script>

<style>

</style>